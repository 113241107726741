<script>
import HeatingCard_1 from "./HeatingCard_1";
import HeatingCard_2 from "./HeatingCard_2";
import HeatingCard_3 from "./HeatingCard_3";
import AbstractDefaultCard from "../default/AbstractDefaultCard";

export default {
    name: "AbstractHeatingCard",
    extends: AbstractDefaultCard,
    components: {
        HeatingCard_1,
        HeatingCard_2,
        HeatingCard_3,
    },
    computed: {
        currentCardComponent() {
            switch (this.currentCardDesign) {
                case 3:
                    return HeatingCard_3;
                case 2:
                    return HeatingCard_2;
                default:
                    return HeatingCard_1
            }
        }
    },
}
</script>
