import { render, staticRenderFns } from "./HeatingCard_3ValuesList.vue?vue&type=template&id=65437ede&scoped=true&"
import script from "./HeatingCard_3ValuesList.vue?vue&type=script&lang=js&"
export * from "./HeatingCard_3ValuesList.vue?vue&type=script&lang=js&"
import style0 from "./HeatingCard_3ValuesList.vue?vue&type=style&index=0&id=65437ede&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65437ede",
  null
  
)

export default component.exports