<template>
    <div>
        <div class="d-flex justify-content-around metric py-2">
            <div v-for="metric in paginatedData"
                 :key="metric.getId()"
                 class="metric-buttons d-flex align-items-center justify-content-center flex-column"
                 :class="{ active : isSelected(metric) }"
                 @click="$emit('change', metric)">
                <Icon :icon-name="metric.getIcon()"
                      width="24"
                      height="24"
                      :fill="metric.getColor()"
                      viewBox="0 0 26 26"/>

                <div class="metric-data py-1"
                     :style="isSelected(metric) ? `color: ${metric.getColor()}` : 'color: #706C6C'">
                    <span v-if="metric.getSlug() === 'heating'">
                        {{ isDetected(metric) ? $t('Включено') : $t('Выключено') }}
                    </span>
                    <span v-else>
                        {{ getMetricLabel(metric) }}
                    </span>
                </div>

                <Icon class="d-block mx-auto"
                      v-if="isSelected(metric)"
                      icon-name="dot"
                      :fill=metric.getColor()
                      width="7"
                      height="7"/>

                <div v-else style="height: 7px"></div>
            </div>
        </div>
    </div>
</template>

<script>

import DefaultCard_2MetricsButtons from "../default/DefaultCard_2MetricsButtons";

export default {
    name: "HeatingCard_2MetricsButtons",
    extends: DefaultCard_2MetricsButtons,
    methods:{
        isDetected(metric) {
            return !!this.timeseries.find(item => item.getSlug() === metric?.getSlug())?.getLatestData()?.getValue();
        }
    }
}
</script>
