<template>
    <div ref="latestValue" class="latest-value">
        <div v-if="value !== null">
            <div v-if="currentMetric.getSlug() === 'heating'"
                 class="d-flex justify-content-center align-content-center text-center">
                <div>
                    <div class="d-inline-flex justify-content-center align-items-center font-weight-bold mt-1 text-uppercase text-white">
                        {{ status ? $t('Включено') : $t('Выключено') }}
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="latest-value__value">{{ Math.round(value) }}</div>
                <div class="latest-value__units">{{ units }}</div>
            </div>
        </div>
        <div v-else>
            <div class="latest-value__value">N/A</div>
            <div class="latest-value__units">{{ units }}</div>
        </div>
    </div>
</template>

<script>

import HeatingCard_1LatestValue from "./HeatingCard_1LatestValue";

export default {
    name: "HeatingCard_2LatestValue",
    extends: HeatingCard_1LatestValue,
}
</script>

<style lang="less" scoped>
.latest-value {
    width: 100%;
    padding: 10px 0 0;


    .status-icon {
        display: inline-block;
        background: #fff;
        padding: 5px 10px;
        border-radius: 5px;
    }

    &__bg {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: #ccc;
        color: #fff;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    &__value {
        text-align: center;
        font-weight: 700;
        font-size: 2rem;
        line-height: 1;
        color: #fff;
    }

    &__units {
        text-align: center;
        font-weight: 500;
        font-size: 1.2rem;
        color: #fff;
    }
}

</style>
