<script>

import LineChart from "../CardLineChart";
import DefaultCard_1LineChart from "../default/DefaultCard_1Chart";
import DefaultCard_1AbstractChart from "../default/DefaultCard_1AbstractChart";
import HeatingCard_1Table from "./HeatingCard_1Table";

export default {
    name: "HeatingCard_1AbstractChart",
    extends: DefaultCard_1AbstractChart,
    components: {
        DefaultCard_1Table: HeatingCard_1Table,
        DefaultCard_1LineChart,
        LineChart
    },
}
</script>
