<template>
    <div :style="{backgroundColor:  tableColor || '#e3e3e3' }" class="gerkon-statistic">
        <div v-for="(item, index) in slicedTimeseries" :key="index" class="mb-2 pl-1 row">
            <div class="text-nowrap pl-1 w-33">
                <i class="fas fa-calendar-alt"></i>
                <strong> {{ item.date }}</strong>
            </div>
            <div class="text-nowrap pl-1 w-33">
                <i class="far fa-clock"></i>
                <strong> {{ item.time }}</strong>
            </div>
            <div class="text-nowrap font-weight-bold pl-1 w-33">
                <span v-if="item.value === 0">{{ $t('Выключено') }}</span>
                <span v-else-if="item.value === 1">{{ $t('Включено') }}</span>
                <span v-else>N/A</span>
            </div>
        </div>
    </div>
</template>

<script>

import moment from "moment-mini";
import DefaultCard_1Table from "../default/DefaultCard_1Table";
import config from "../../../config";

export default {
    name: "HeatingCard_1Table",
    extends: DefaultCard_1Table,
    computed: {
        slicedTimeseries() {
            return this.metricTimeseries?.slice(0, 6)?.map(item => {
                return {
                    date: moment(item.getDate()).format(config.dateTimeFormats.DDMMYYYY),
                    time: moment(item.getDate()).format(config.dateTimeFormats.HHmmss),
                    value: parseInt(item?.getValue())
                };
            });
        },
    },
}
</script>

<style scoped lang="less">

.gerkon-statistic {
    border-radius: 10px;
    height: 190px;
    padding: 1rem 1.5rem;
    font-size: 13px;
    color: #fff;

    &-item {
        margin: 5px 0;
    }

    &-icon {
        width: 20px;
        height: 20px;
        border-radius: 5px;
        background-color: white;
    }

    &-time {
        min-width: 80px;
        font-weight: 600;
        line-height: 14px;
    }
}
</style>
