<script>

import Icon from "../../Icon";
import DefaultCard_1MetricsButtons from "../default/DefaultCard_1MetricsButtons";
import HeatingCard_1LatestValue from "./HeatingCard_1LatestValue";
import DefaultCard_1Header from "../default/DefaultCard_1Header";
import DefaultCard_1 from "../default/DefaultCard_1";
import HeatingCard_1AbstractChart from "./HeatingCard_1AbstractChart";

export default {
    name: "HeatingCard_1",
    extends: DefaultCard_1,
    components: {
        HeaderComponent: DefaultCard_1Header,
        MetricsButtonsComponent: DefaultCard_1MetricsButtons,
        LatestValueComponent: HeatingCard_1LatestValue,
        ChartComponent: HeatingCard_1AbstractChart,
        Icon
    }
}
</script>
