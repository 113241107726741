<script>

import Icon from "../../Icon";
import DefaultCard_1Header from "../default/DefaultCard_1Header";
import DefaultCard_3 from "../default/DefaultCard_3";
import HeatingCard_3ValuesList from "./HeatingCard_3ValuesList";
import HeatingCard_1AbstractChart from "./HeatingCard_1AbstractChart";

export default {
    name: "DefaultCard_3",
    extends: DefaultCard_3,
    components: {
        HeaderComponent: DefaultCard_1Header,
        ValuesListComponent: HeatingCard_3ValuesList,
        ChartComponent: HeatingCard_1AbstractChart,
        Icon
    },
}
</script>
