<script>

import Icon from "../../Icon";
import DefaultCard_1Header from "../default/DefaultCard_1Header";
import DefaultCard_2 from "../default/DefaultCard_2";
import HeatingCard_2LatestValue from "./HeatingCard_2LatestValue";
import HeatingCard_2MetricsButtons from "./HeatingCard_2MetricsButtons";
import HeatingCard_1AbstractChart from "./HeatingCard_1AbstractChart";

export default {
    name: "HeatingCard_2",
    extends: DefaultCard_2,
    components: {
        HeaderComponent: DefaultCard_1Header,
        MetricsButtonsComponent: HeatingCard_2MetricsButtons,
        LatestValueComponent: HeatingCard_2LatestValue,
        ChartComponent: HeatingCard_1AbstractChart,
        Icon
    }
}
</script>
